.radius-10{
	border-radius:10px;
}
.radius-20{
	border-radius:20px;
}
.radius-circle{
	border-radius:50%;
}
.margin-t40 {
	margin-top: 40px;
}
.margin-t30 {
	margin-top: 30px;
}
.mb30{
	margin-bottom: 30px;
}
.mb20{
	margin-bottom: 20px;
}
.mb10{
	margin-bottom: 10px;
}
.margin-t20 {
	margin-top: 20px;
}
.margin-t10 {
	margin-top: 10px;
}
.f14 {
    font-size: 14px;
}
.red-link{
	color:#FF0000;
}
.red-link:hover{
	color:#FF0000;
	opacity: 0.8;
}
.blue-link {
    color: #00acc0;
}
.blue-link:hover {
    color: #1f9ead;
}
.btn {
    box-shadow: unset!important;
}
button.btn.btn-transparent {
    border: 1px solid #000;
}
button.btn.btn-transparent:hover {
    background: #000;
    color: #fff;
}
.btn.btn-primary:hover {
    background: #db655a;
}
.btn.btn-primary {
    background: #E77469;
    border-color: #E77469;
}
.btn-dark:hover {
    background-color: #3c4248;
    border-color: #3c4248;
}
.btn.btn-default {
    background: #D9D9D9;
    border: 1px solid #D9D9D9;
}
.btn.btn-default:hover {
    background: #d3d2d2;
}
.btn.gray-transparent-btn:hover {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0,0,0,1);
}
.btn.gray-transparent-btn {
    border: 1px solid rgba(0, 0, 0, 0.4);
    color: rgba(0,0,0,0.6);
}
.btn-lg{
    padding: 13px 20px;	
}
.btn-md{
    padding: .375rem 20px;	
}
.full-btn {
    width: 100%;
}
.bg-gray {
	background: #D9CEB4;
}
.full-width-btn {
    width: 100%;
}
.download-btn {
    width: 55px;
    height: 55px;
    background: rgba(0, 172, 192, 0.59);
    border-radius: 12px;
    border-width: 0;
    color: #fff;
}
.download-btn:hover {
    background: rgba(0, 172, 192, 0.7);
}
.btn-gray{
    background: #f0f0f0;
}
.btn-gray:hover {
    background: #ededed;
}
/*20/03/2023*/
.form-control {
	/* height: 60px; */
    
    height: 45px;
    border-color: #ddd;
    border-radius: 0;
    outline: unset;
    box-shadow: unset;
    box-shadow: unset;
}

.form-control:focus {
    box-shadow: unset;
}
.btn.btn-success {
    background: #1E8400;
    border-color: #1E8400;
    width: 100px;
    border-radius: 10px;
}
.btn.btn-success:hover {
    background: #269107;
}
.table-action .btn.btn-success {
    background: #1E8400;
    border-color: #1E8400;
    width: 80px;
    border-radius: 4px;
    font-size: 12px;
}
.table-action .btn.btn-danger {
    background: #DA0000;
    border-color: #DA0000;
    width: 80px;
    border-radius: 4px;
    font-size: 12px;
}
.form-control {
    box-shadow: unset!important;
    outline: 0!important;
}
.table-sec.common-table.margin-t30 {
    width: 100%;
}

.search .form-control {
    background: rgba(230, 230, 230, 0.6);
    border-radius: 10px;
    height: 42px;
    float: right;
    margin-right: 20px;
}

h3.title {
    font-size: 19px;
    margin: 17px 0px 10px;
}
button.btn.activities {
    background: rgba(230, 230, 230, 0.6);
    width: 50%;
    height: 49px;
    border-radius: 4px;
}
.create-venue-form button.btn.btn-dark {
    height: 46px;
    width: 187px;
}
.create-venue-form form {
    display: flex;
    column-gap: 1px;
}
.create-venue-form .form-control {
    background: rgba(230, 230, 230, 0.6);
    border-radius: 10px;
    height: 50px;
    border-width: 0;
}
.comunication-form .btn {
    height: 50px;
    width: 140px;
    float: right;
}
.dashboard-auto-wrapper-2 {
    background: #fff;
    padding: 25px;
    border-radius: 10px;
    height: 50%;
}

.dashboard-top-btn-2 button.btn.btn-dark {
    font-size: 13px;
    margin: 23px 5px 4px;
}
.dashboard-top-btn-2 {
    display: flex;
    margin-bottom: 20px;
}
/*29-03-2023 Preeti*/
.dashboard-top-btn-2 {
   
    margin-top: 45px;
}
.custom-pop-box.dashboard-popup {
    height: 265px;
}
.modal-content.dashboard_pop_up {
    top: 125px;
}
.uploade-file.Contents-file {
    display: grid;
}
.Add-option {
    margin: 0px 0px 26px;
}

/*30-03-2023 Preeti*/
.skip {
    margin-top: 17px;
}

.time {
    border-color: #cbd0dd;
    display: flex;
}
.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.input-group-addon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    background-color: #282041de;
}
.input-group-addon {
    color: white;
}
.time {
    width: 451px;
}
button.btn.btn-dark.green {
    /* background: #282041b8; */
    background:  #282041eb;
    border-color: #282041eb;
}

button.btn.btn-dark.blue {
    background: #282041eb;
    border-color: #282041eb;
}

button.btn svg path {
    color: #282041eb;
}

.dashschedule {
    height: 182px !important;
}
.dashschedule .dashboard-top-btn-2 {
    margin: 20px !important;
}
.dashboard_schedule .modal-dialog{
    margin : 14.75rem 26rem !important;
}

/*31-03-2023 Preeti*/
.copy-tournament-date {
    margin-bottom: 35px;
    margin-top: 14px;
}

/*3-04-2023 Preeti*/
button.btn.btn-dark {
    background: #282041de;
    border-color: #282041de;
}
/* button.btn.btn-dark:hover {
    background: #fff;
    color: #282041de;
} */
.input-group.games-time {
    margin: 0px 0px 18px;
}

/*4-04-2023 Preeti*/


button.btn.btn-transparent {
    border: 1px solid #282041de;
}
/*6-04-2023 Preeti*/

.dashboard-top-btn h4 {
    flex: auto;
    margin: 11px 13px 4px;
    font-weight: 600;
}
/*10-04-2023 Preeti*/

.signup {
    color: #282041de;
    cursor: pointer;
}

/*11-04-2023 Preeti*/

.col-sm-12.Signup {
    margin-top: 15px;
}
button.btn.btn-transparent:hover {
    background: #282041de;
    color: #fff;
}

button.btn.btn-dark.savesubmit {
    width: 141px;
    height: 48px;
}

/*11-04-2023 Preeti*/

label.custom-file-upload {
    padding: 10px 12px;
    cursor: pointer;
    background: #282041de;
    border-color: #282041de;
    color: #fff;
    border-radius: 10px;
    width: 143px;
    /* height: 52px;*/
    text-align: center;
    display: block;
}

label.profile-file-upload {
    cursor: pointer;
    display: block;
    color:#282041de;
}


.custom-file-upload input[type="file"], .profile-file-upload input[type="file"] {
    display: none;
}
/*13-04-2023 Preeti*/
.common-table a {
    color: #000;
}

.MuiTableCell-body a {
    color: rgba(0, 0, 0, 0.87) !important;
}

.MuiTableCell-body a:hover {
    color: rgba(0, 0, 0, 0.87) !important;
}

button.btn.btn-dark.green.green.addfamily {
    margin-bottom: 18px;
}


.pop-form label.custom-file-upload {
    font-size: 18px;
    padding: 10px 12px;
    cursor: pointer;
    background: #282041de;
    border-color: #282041de;
    color: #fff;
    border-radius: 10px;
    width: 143px;
    text-align: center;
    display: block;
}

button.btn.btn-dark.green.green.create {
    width: 109px;
    height: 42px;
}


.create-from-btn.submission.datesubmission {
    margin-top: 33px;
}

button.btn.btn-dark.green.rulesbtn {
    margin: 0px 0px 25px;
}

/*14-04-2023 Preeti*/
.dashboard-top-btn h5 {
    flex: auto;
    margin: -13px 13px 4px;
    font-weight: 600;
}

button.btn.btn-transparent.back {
    margin-top: 22px;
}

button.btn.btn-dark.green.team {
    margin-bottom: 20px;
}

button.exportbtn {
    margin: 0 0 1rem 0.5rem;

}
